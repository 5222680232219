@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
}

@font-face {
  font-family: 'GMCafe';
  src: url('/gmcafe.ttf');
}

@font-face {
  font-family: 'GMCafe Skinny';
  src: url('/gmcafe_skinny.otf');
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: 100dvh;
  }
}

.mobile-nav-height {
  height: calc(100dvh - 4rem);
}

.clip-home-section {
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
}

.text-shadow {
  text-shadow: 0 4px 2px #e1a4cc;
}

.text-border-white {
  text-shadow: 6px 0 0 #fff, -6px 0 0 #fff, 0 6px 0 #fff, 0 -6px 0 #fff, 4px 4px #fff,
    -4px -4px 0 #fff, 4px -4px 0 #fff, -4px 4px 0 #fff;
}

.migration-drop-shadow {
  filter: drop-shadow(0 0 4px #f4dffe) drop-shadow(0 0 4px #f4dffe)
}

@media (min-width: 768px) {
  .clip-home-section {
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
  }

  .bg-two-tone-gray {
    background-color: #ffffff;
    background-image: linear-gradient(to right, #ffffff 50%, #f3f4f6 50%);
  }

  .bg-two-tone-purple {
    background-color: #ffffff;
    background-image: linear-gradient(to right, #ffffff 50%, #8946ab 50%);
  }
}

.cursor {
  cursor: url('/cursor.png') 18 7, auto;
}

.cursor a,
.cursor button,
.cursor-pointer {
  cursor: url('/pointer.png') 4 5, pointer;
}

button.ju367v8i {
  cursor: url('/pointer.png') 4 5, pointer !important;
}

.squiggle {
  background-size: auto 101%;
  background-repeat: no-repeat;
  background-position: 75%;
  background-image: url('/svgs/squiggle.svg');
  height: 100%;
  z-index: 1;
}

.moo-tag-shadow {
  background-image: url('../public/profile/moo_tag_shadow.png');
  background-repeat: no-repeat;
  background-position: 11.5rem 11px;
  background-size: 22px;
}

@media (min-width: 768px) {
  .moo-tag-shadow {
    background-image: url('../public/profile/moo_tag_shadow.png');
    background-repeat: no-repeat;
    background-position: 21.6rem 28px;
    background-size: 31px;
  }
}

.moo-verified-herd {
  background-image: url('../public/profile/verified_herd.png');
  background-repeat: no-repeat;
  background-position: right 1rem bottom 1rem;
  background-size: 12rem;
}

.moo-verified-keek {
  background-image: url('../public/profile/verified_keek.png');
  background-repeat: no-repeat;
  background-position: right 1rem bottom 1rem;
  background-size: 12rem;
}
